import * as React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import SlateAnimationWebM from "../../images/plateanimation.webm"
import SlateAnimationMP4 from "../../images/plateanimation.mp4"
import SlateAnimationJPG from "../../images/slate-heinrig.png"

import PenVidWebM from "../../images/pen_vid.webm"
import PenVidMP4 from "../../images/pen_vid.mp4"
import PenVidJPG from "../../images/quicksticks/qs_hold.jpg"


const quicksticksPage = () => {
    return (
      <Layout>
        <Seo
        title="Quicksticks"
        description="Custom and eco-friendly movie slate."/>
        <div className="hero">
          <div className="pt-20 lg:py-32 m-auto w-4/5 md:w-2/5 flex flex-col lg:flex-row-reverse items-center lg:space-x-5 justify-center">
            <StaticImage src="../../images/quicksticks/qs_hold.jpg" className="rounded-xl flex-shrink-0 w-full" alt="slate"/>
            <div className="flex flex-col items-center py-5 min-w-full">
              <h1 className="text-4xl lg:text-6xl font-semibold text-right">aaand action.<br/>the cool way.</h1>
              <p className="text-right py-5 font-semibold">made from 100% recycled acrylic, <br />
locally and carefully sourced wood, <br />
with your logo and custom design on it,<br />
the quicksticks are our take on a modern film slate. <br /><br />
make it yours.<br /><br />
</p>
              <div className="py-5 flex flex-col items-center">
                <h3 className="text-lg font-semibold">80€</h3>
                <h5 className="text-sm">+ VAT & shipping</h5>
              </div>
              <a className="button" href="https://shop.heinrig.tech/?product=quicksticks">get yours</a>
              
              <p className="text-center py-5 text-sm">currently available in the EU, EEA and Switzerland. <br /> more countries to come.</p>
            </div>
            </div>
        </div>
        <div className="tldr py-20">
          <div className="py-10">
            <h2 className="font-semibold text-center text-2xl lg:text-4xl ">tl;dr</h2>
            <p className="font-semibold text-center text-lg lg:text-xl">why should i care?</p>
          </div>
          <div className="flex w-full lg:w-4/5 xl:w-1/2 m-auto flex-wrap justify-center">
            <div className="tldr-section">
              <img src={"/tldr-icons/eco.svg"} alt="" className="tldr-icon" />
              <h5 className="tldr-text">made from recycled acrylic and local oak</h5>
            </div>
            <div className="tldr-section">
              <img src={"/tldr-icons/magnet.svg"} alt="" className="tldr-icon" />
              <h5 className="tldr-text">magnetic<br/> marker holder</h5>
            </div>
            <div className="tldr-section">
              <img src={"/tldr-icons/quickrelease.svg"} alt="" className="tldr-icon" />
              <h5 className="tldr-text">quickrelease<br /> plate change</h5>
            </div>
            <div className="tldr-section">
              <img src={"/tldr-icons/friction.svg"} alt="" className="tldr-icon" />
              <h5 className="tldr-text">variable<br/> friction adjustment</h5>
            </div>
            <div className="tldr-section">
              <img src={"/tldr-icons/symmetry.svg"} alt="" className="tldr-icon" />
              <h5 className="tldr-text">symmetrical<br/> operation</h5>
            </div>
            <div className="tldr-section">
              <img src={"/tldr-icons/custom.svg"} alt="" className="tldr-icon" />
              <h5 className="tldr-text">custom<br/> plate design</h5>
            </div>
            <div className="tldr-section">
              <img src={"/tldr-icons/hand.svg"} alt="" className="tldr-icon" />
              <h5 className="tldr-text">one hand<br/> operation</h5>
            </div>
            <div className="tldr-section">
              <img src={"/tldr-icons/heart.svg"} alt="" className="tldr-icon" />
              <h5 className="tldr-text">handmade<br/> in germany</h5>
            </div>
          </div>
        </div>

        <div className="product-segment lg:flex-row ">
          <video video autoPlay muted loop playsInline className="product-segment-image">
            <source src={SlateAnimationWebM} type="video/webm" />
            <source src={SlateAnimationMP4} type="video/mp4"/>
            <img src={SlateAnimationJPG} alt="A customized slate"/>
          </video>

          <div className="product-segment-info">
            <h3 className="product-segment-heading">your slate,<br />your style.</h3>
            <p className="product-segment-text">put whatever you want on your slate. <br /><br />
            logo? your new hip film title? a meme? easy. <br /><br />
            either send us your logo and text or customize one of our templates.
            we will engrave it, and fill it with color. <br /><br /> for now only black & white designs are possible.
            </p>
          </div>
        </div>
        
        <div className="product-segment lg:flex-row-reverse">
          <StaticImage src="../../images/quicksticks/IMG_2033.jpeg" alt="" className="product-segment-image" />
          <div className="product-segment-info items-end">
            <h3 className="product-segment-heading text-right">new film,<br />
new plate,<br />
over and over.</h3>
            <p className="product-segment-text text-right">sometimes you might want to swap out a customized plate for another one.<br /><br />
            our quick release mechanism is tough enough to hold your plate without any give, but also doesn't require any tools or big guns to swap one for another. <br /><br />
            just grip the plate with your thumb on the wooden sticks and give it a gentle push, which is all it takes.
            </p>
          </div>
        </div>

        <div className="product-segment lg:flex-row">
        <video autoPlay muted loop playsInline className="product-segment-image">
            <source src={PenVidWebM} type="video/webm" />
            <source src={PenVidMP4} type="video/mp4" />
            <img src={PenVidJPG} alt="magnetic pen holder" />
          </video>
          <div className="product-segment-info">
            <h3 className="product-segment-heading">never lose your pen,<br/>even in a hustle.</h3>
            <p className="product-segment-text">in our experience slate pens always get forgotten somewhere. <br /><br />
            the quicksticks have a magnetic pen holder allowing you to quickly grab the pen, write on the slate, and put it back. <br /><br />
            no clips, no fuzz, just magnets. 
            </p>
          </div>
        </div>

        <div className="product-segment lg:flex-row-reverse">
          <StaticImage src="../../images/quicksticks/IMG_2041.jpeg" alt="" className="product-segment-image" />
          <div className="product-segment-info items-end">
            <h3 className="product-segment-heading text-right">materials to <br/>do good.</h3>
            <p className="product-segment-text text-right">
              the sticks are made from locally sourced wood, to reduce transport and maintain a low ecological footprint.
              <br /><br />
              the acrylic is made from 100% 
              recycled pre- and post-consumer waste, 
              reducing the co2-emissions by 70%. 
              <br /><br />
              we make sure that we use materials which are not harmful to you or the environment. <br />
              more about that in our <Link to="/philosophy" className="inlinelink">philosophy</Link>
            </p>
          </div>
        </div>

        <div className="product-segment lg:flex-row">
          <StaticImage src="../../images/quicksticks/IMG_2036.jpeg" alt="" className="product-segment-image" />
          <div className="product-segment-info">
            <h3 className="product-segment-heading">not too big,<br />
not too small,<br />
just right.</h3>
            <p className="product-segment-text">we thought that previous full size slates are just too big and clunky. <br /><br />
            a smaller slate can be easily operated by one hand, takes up less space and weighs less. <br /><br />
            all this while having enough space to write a poem on it (a small one at least). <br />
            </p>
          </div>
        </div>

<div className="flex flex-col justify-center items-center w-4/5 m-auto">
<a className="button" href="https://shop.heinrig.tech/?product=quicksticks">get yours</a>
              <p className="text-center py-5 text-sm">currently available in the EU, EEA and Switzerland. <br /> more countries to come.</p>
</div>

      </Layout>
  )
}



export default quicksticksPage